import React,{useState,useEffect,useRef}  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Data from "../config/DJs"
import Edit from "../components/form/Edit";
import {BaseSelect,BaseFormField,FormSubmit} from "../styles/Global"
import { GetList ,GetFeed} from "../utils/ApiFunctions";
import axios from "axios";
import mainjson from '../config/main';
import {ucfirst,GetUrlParams,MakeUrlParams} from "../utils/HelperFunctions"


const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const ListContainer = styled.div`
  padding-bottom:40px;
`;

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: block;
  margin-bottom: .5rem;
  font-weight: 400;
`;

const Table = styled.table`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  display: block;
  margin-bottom: .5rem;
  font-weight: 400;
  width:50vw;
  border-collapse: collapse;
  border-spacing: 0px;
`;

const ListTitleBold = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: block;
  margin-bottom: .5rem;
  font-weight: bold;

`;


const FirstTD = styled.td`
border: 1px solid black;
padding: 10px;
width:200px;
`;

const FirstTDBold = styled.td`
border: 1px solid black;
padding: 10px;
width:200px;
font-weight:bold;
`;

const TD = styled.td`
border: 1px solid black;
padding: 10px;
`;

const Pad = styled.div`
  padding-bottom:30px;
`;


const InputContainer = styled.div`
 
`;

const Select = styled(BaseSelect)`

`

const Top = styled.div`
padding:25px;

`

const Container = styled.div`
padding:25px;
background-color:white;
`

const ScrollContainer = styled.div`
position:absolute;
overflow:auto;
height:100%;
width:100%;
`




export default function Page() {
    const [options, setOptions] = useState([]);
    const [text, setCurrentText] = useState(null);
    const input = useRef();
    const inputRef = useRef();
    const [value, setValue] = useState(0);
    const [imported, setImported] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importasvalue, setimportasValue] = useState(0);
    const filenameRef = useRef();
    const [active, setActive] = useState(true);
    const [from, setFrom] = useState("none");
    const [kid, setKid] = useState("none");
    const [adult, setAdult] = useState("none");
    const [fromState, setFromState] = useState("none");
    const [to, setTo] = useState("none");
    const [fromCopy, setFromCopy] = useState("none");
    const [name, setName] = useState("");
    const [data, setData] = useState({});

    async function getFeed(){
        let params = GetUrlParams();
        setName(params['subname']);
        let data = await GetFeed("sectionStats",{id:params['subid']});
        setData(data.data);
    }

function GetPercent(total,single){
    if (!total) {
        return "";
    }
    let percent = 100 / total;
    let ret = Math.round(single * percent);
    return ret + "%";
}

function makeName(obj) {
  let names = [];
  if (obj.point_name) {
    names.push(obj.point_name);
  }
  if (obj.point_name_adult) {
    names.push(obj.point_name_adult);
  }
  return names.join (" / ");

}



  useEffect(() => {
    getFeed();
}, []);    
    

useEffect(() => {
    
}, [data]);    
    


  return (
    <Layout>
         <ScrollContainer>
        <Header>
            <Title>
                    Send push to all users
            </Title>

        </Header>
        <Top>
        <Container>
       
        <ListContainer>
            <Table>
                <tr><FirstTD>Total</FirstTD><TD>{data?.total | 0}</TD><TD></TD></tr>
                <tr><FirstTDBold>Adult</FirstTDBold><TD>{data?.adult | 0}</TD><TD>{GetPercent(data?.total,data?.adult)}</TD></tr>
                <tr><FirstTD>Adult Image</FirstTD><TD>{data?.adult_image | 0}</TD><TD>{GetPercent(data?.total,data?.adult_image)}</TD></tr>
                <tr><FirstTD>Adult Audio</FirstTD><TD>{data?.adult_audio | 0}</TD><TD>{GetPercent(data?.total,data?.adult_audio)}</TD></tr>
                <tr><FirstTD>Adult Video</FirstTD><TD>{data?.adult_video | 0}</TD><TD>{GetPercent(data?.total,data?.adult_video)}</TD></tr>
                <tr><FirstTD>Adult Game</FirstTD><TD>{data?.adult_game | 0}</TD><TD>{GetPercent(data?.total,data?.adult_game)}</TD></tr>
                <tr><FirstTDBold>Kids</FirstTDBold><TD>{data?.kid | 0}</TD><TD>{GetPercent(data?.total,data?.kid)}</TD></tr>
                <tr><FirstTD>Kid Image</FirstTD><TD>{data?.kid_image | 0}</TD><TD>{GetPercent(data?.total,data?.kid_image)}</TD></tr>
                <tr><FirstTD>Kid Audio</FirstTD><TD>{data?.kid_audio | 0}</TD><TD>{GetPercent(data?.total,data?.kid_audio)}</TD></tr>
                <tr><FirstTD>Kid Video</FirstTD><TD>{data?.kid_video | 0}</TD><TD>{GetPercent(data?.total,data?.kid_video)}</TD></tr>
                <tr><FirstTD>Kid Game</FirstTD><TD>{data?.kid_game | 0}</TD><TD>{GetPercent(data?.total,data?.kid_game)}</TD></tr>
                
                


            </Table>
            <ListTitle><br/><br/>Most populair points</ListTitle>
            <Table>
            <tr><FirstTDBold>Nr of visits</FirstTDBold><FirstTDBold>Name</FirstTDBold></tr>
            {data?.populairroads && data?.populairroads.map(function(object, i){
              console.log("object",object);
                return <tr><FirstTD>{object.counter}</FirstTD><TD>{makeName(object)}</TD></tr>;
            })}
              </Table>
            </ListContainer>
            </Container>
            </Top>
            </ScrollContainer>
    </Layout>
  );
}